var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',{staticClass:"content-header"},[_c('b-col',{staticClass:"content-header-left mb-2",attrs:{"cols":"12","md":"12"}},[_c('b-row',{staticClass:"breadcrumbs-top"},[_c('b-col',{attrs:{"cols":"12"}},[_c('h1',{staticClass:"custom-header-title float-left pr-1 mb-0"},[_vm._v(" Create New Store ")])])],1)],1)],1),_c('b-card',[_c('validation-observer',{ref:"storeCreateForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitForm($event)}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"md-6"}},[_c('b-form-group',{attrs:{"label":"Store Name*","label-for":"name","label-cols-md":"3"}},[_c('validation-provider',{attrs:{"name":"Name","vid":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","state":(errors.length > 0 || _vm.nameValidation) ? false : null,"name":"name","placeholder":"Store Name"},on:{"input":function($event){_vm.nameValidation == true ? _vm.nameValidation = false : null}},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(_vm.nameValidation)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.nameError)+" ")]):_vm._e()]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"md-6"}}),_c('b-col',{attrs:{"cols":"md-6"}},[_c('validation-provider',{attrs:{"name":"Department","vid":"department","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Department*","label-for":"department","label-cols-md":"3","state":(errors.length > 0 || _vm.departmentValidation) ? false : null}},[_c('v-select',{attrs:{"id":"department","label":"name","placeholder":"Select from list","options":_vm.departments,"reduce":function (name) { return name._id; },"clearable":false},on:{"input":function($event){_vm.departmentValidation == true ? _vm.departmentValidation = false : null}},model:{value:(_vm.department),callback:function ($$v) {_vm.department=$$v},expression:"department"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(_vm.departmentValidation)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.departmentError)+" ")]):_vm._e()],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"md-6"}}),_c('b-col',{attrs:{"cols":"md-9"}},[_c('validation-provider',{attrs:{"name":"Roles","vid":"roles","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Managed by Roles*","label-for":"h-user-roles","label-cols-md":"2","state":(errors.length > 0 || _vm.roleValidation) ? false : null}},[_c('b-form-tags',{attrs:{"size":"lg","add-on-change":"","no-outer-focus":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var tags = ref.tags;
var inputAttrs = ref.inputAttrs;
var inputHandlers = ref.inputHandlers;
var disabled = ref.disabled;
var removeTag = ref.removeTag;
return [(tags.length > 0)?_c('ul',{staticClass:"list-inline d-inline-block mb-1"},_vm._l((tags),function(tag){return _c('li',{key:tag,staticClass:"list-inline-item"},[_c('b-form-tag',{attrs:{"title":tag,"disabled":disabled,"variant":"danger"},on:{"remove":function($event){return removeTag(tag)}}},[_vm._v(" "+_vm._s(_vm.resolveRoleName(tag))+" ")])],1)}),0):_vm._e(),_c('b-form-select',_vm._g(_vm._b({scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('option',{attrs:{"disabled":"","value":""}},[_vm._v(" Choose a Role.... ")])]},proxy:true}],null,true)},'b-form-select',inputAttrs,false),inputHandlers),_vm._l((_vm.roleOptions),function(role,ind){return _c('option',{key:ind,domProps:{"value":role._id}},[_vm._v(" "+_vm._s(role.role)+" ")])}),0)]}}],null,true),model:{value:(_vm.roles),callback:function ($$v) {_vm.roles=$$v},expression:"roles"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(_vm.roleValidation)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.roleError)+" ")]):_vm._e()],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"md-9"}},[_c('b-form-group',{attrs:{"label":"Remarks","label-for":"remarks","label-cols-md":"2"}},[_c('validation-provider',{attrs:{"name":"Remarks","vid":"remarks","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"remarks","name":"remarks","rows":"3","placeholder":"Remarks"},on:{"input":function($event){_vm.remarksValidation == true ? _vm.remarksValidation = false : null}},model:{value:(_vm.remarks),callback:function ($$v) {_vm.remarks=$$v},expression:"remarks"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(_vm.remarksValidation)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.remarksError)+" ")]):_vm._e()]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"md-3"}}),_c('b-col',{attrs:{"cols":"md-6"}},[_c('validation-provider',{attrs:{"name":"Status","vid":"status","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Status*","label-for":"h-team-status","label-cols-md":"3","state":(errors.length > 0 || _vm.statusValidation) ? false : null}},[_c('v-select',{attrs:{"id":"h-team-status","label":"title","options":_vm.option,"reduce":function (title) { return title.code; },"clearable":false},on:{"input":function($event){_vm.statusValidation == true ? _vm.statusValidation = false : null}},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(_vm.statusValidation)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.statusError)+" ")]):_vm._e()],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"md-6"}})],1),_c('b-nav',{staticClass:"wrap-border save-nav"},[_c('li',{staticClass:"nav-item ml-auto"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"button","variant":"flat-primary","to":{ name: 'inventory-store-list' }}},[_vm._v(" Cancel ")])],1),_c('li',{staticClass:"nav-item mr-1"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"submit","variant":"success","disabled":invalid}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"SaveIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v("Save New Store")])],1)],1)])],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }